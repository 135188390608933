/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


#office-dialog-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

#officeDialog {
	animation: var(--standard-fade);
	padding: 0;
	border: none;
}

#officeDialog::backdrop {
	background: rgb(0 0 0 / 35%);
}

/* Header - Office name */
.office-header {
	margin-bottom: 1rem;
	font-weight: bold;
}

/* Header - pill box showing inactive site: pending, expired */
/* Background colour is set separately  */
.inactive-header {
	vertical-align: middle;
	display: inline-block;
    border: 0.1rem solid white;
    padding: 0.2rem 1rem;
    color: white;
    border-radius: var(--pill-corners);
    font-size: 0.6em;
    margin-left: 1rem;
}

.expired-header {
	background-color: var(--color-std-red);
}

.pending-header {
	background-color: gray;
}

#office-modal-header {
	z-index: 2;
	max-height: 100%;
	box-shadow: rgb(38, 57, 77) 0 1rem 1.2rem -1rem;
	padding-left: 2rem;
	padding-bottom: 0.5rem;
	background: var(--color-std-dark);
	/* TODO: transition would be nice but needs some tweaking */
	/* transition: max-height 500ms ease; */
}

#office-body {
	z-index: 1;
	height: inherit;
	overflow-y: auto;
	max-height: calc(100vh - 6rem);
}

/* DESKTOP */
@media (min-width: 769px) {
	#officeDialog {
		width: 96%; /* ensure loading dialog is full sized */
		height: 96%; /* ensure loading dialog is full sized */
		border-radius: var(--rounded-corners);
		margin-top: 1em;
		max-width: 117rem;
		box-shadow: rgba(0, 0, 0, 0.56) 0 2.2rem 7rem 0.4rem;
	}

	.office-header {
		font-size: 2.5rem;
		margin-top: 2rem;
		line-height: normal;
	}

	#office-body {
		padding: 1rem 2.5rem 0 2.5rem;
	}
}

/* MOBILE  */
@media (max-width: 768px) {

	#officeDialog {
		border-radius: 0;
		max-width: 100vw;
		width: 100vw;
		max-height: 100vh;
		height: 100vh;
		margin: 0;
	}

	#office-modal-header.header-shrink {
		max-height: 4rem;
		overflow: hidden;
	}
	
	#office-modal-header.header-shrink .office-header {
		white-space: nowrap; 
		overflow: hidden; 
		text-overflow: ellipsis; 
	}

	.office-header {
		font-size: 1.8rem;
		margin-top: 1rem;
	}

	#office-body {
		padding: 1rem 1rem 0 1rem;
	}
}

#office-alias {
	font-weight: lighter;
	font-style: italic !important;
}

#office-close-button {
	float: right;
	position: absolute;
	right: 0;
	top: 0;
	margin: 1rem;
	background: none;
	border: none;
}