/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


.map-container {
    width: 100%;
    height: 50rem; 
    background-color: #f5f5f5;
    border: 0.1rem solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.24) 0 0.3rem 0.8rem;
}

/* #map-loading {
    text-align: center;
    font-family: "Noto Sans",sans-serif;
    font-size: 2rem;
    padding: 3rem;
} */

/* Bing maps infobox */
.infobox {
    border-radius: var(--rounded-corners);
}

.infobox-body {
    font-family: "Noto Sans",sans-serif !important;
    /* border: 0.1rem solid #ddd !important; */
}

.infobox-info {
    line-height: 1.5em;
    /* color: black !important; */
    font-family: "Noto Sans",sans-serif !important;
}

.bing-title {
    font-weight: bold;
    margin-bottom: 1rem;
}

.bing-service-label {
    margin-top: 1.5rem;
}