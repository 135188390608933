/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


/* Standard vars to use throughout application */
:root {
  /* COLOURS */
  --color-std-dark: #2e5274;
  --color-std-light: #ccc;
  --color-std-light-blue: #ebf2fc;
  --color-std-red: #cd0000; /* darker than regular, stands out less */
  --color-std-green: #007100; /* darker than regular, stands out less */

  /* MEASUREMENTS */
  --rounded-corners: 0.6rem; /* slightly rounded corners */
  --pill-corners: 1rem; /* pill shaped corners */

  /* ANIMATIONS */
  --standard-fade: fadeIn 500ms ease forwards;
}

/* Standard fade-in + slide up */
@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(5rem);
      /* visibility: hide */  /* home.css uses this */
  }
  100% {
      opacity: 1;
      transform: translateY(0);
      /* visibility: visible; */
    }
}

/* * DORB-specific - START */

#wb-cont {
  text-align: center;
  font-size: 2.3em;
}

.expired-flag {
  color: var(--color-std-red);
  margin-left: 1rem;
}

/* Active in the future */
.pending-flag {
  color: gray;
  margin-left: 1rem;
}

/* This one is primarily meant for if you to plan to add a transition */
.dorb-hide {
  opacity: 0 !important;
  /* visibility: hidden !important; */
  pointer-events: none;
}

.hide {
  display: none;
}

/* Put on fields tht need to support multi-line \n */
.line-break-support {
  white-space: pre-wrap;
}

.bold {
  font-weight: bold;
}

.float-right {
  float: right;
}

.row-negative-margin-override {
  margin-left: 0;
  margin-right: 0;
}

/* Situations where it's a button but want to make it look like a link */
.fake-link-button {
  padding: 0;
  background: none;
  border: none;
  text-decoration: underline;
}

/* * DORB-specific - END */

/* TODO: take out old un-used stuff */

table tr {
  font-size: 1.6rem;
}

ol.breadcrumb {
	margin-left:1.5rem;
}
#wb-bc a {
	padding-left:0;
	padding-right:0;
}

.has-error {
	border-color: rgb(169, 68, 66);
}

.mfp-bg {
	opacity: .80;
}

/* react-date-picker custom styling */

.react-calendar {
  width: 35rem;
  max-width: 100%;
  background: white;
  border: 0.1rem solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 70rem;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 4.4rem;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 4.4rem;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}


.react-date-picker {
  display: inline-flex;
  position: relative;
  padding-right:0;
  padding-left:0.6rem;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 0;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 0.2rem;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 0.2rem 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 0.1rem;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(0.1rem +  0.54em);
}
.react-date-picker__inputGroup__leadingZero {
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-left: 0.1rem;
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 0 0 0 0.6rem;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 35rem;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-date-picker,.form-control {
	padding-top:0;
	padding-bottom:0;
}

.react-date-picker__calendar-button button, .react-date-picker__calendar-button span.ui-datepicker-trigger {
	margin: -0.1rem 0 0 -0.1rem;
}
div.form-control {
	border: 0.1rem solid #ccc;
  border-radius: 0.4rem;
}
span.react-date-picker-calendar-button span.ui-datepicker-trigger {
	border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
input.react-date-picker__inputGroup__input {
	border:0;
}
.has-error span.react-date-picker-calendar-button span.ui-datepicker-trigger {
	border-color: rgb(169, 68, 66);
}

section.global-alert section.alert {
	margin-top: 0;
	margin-bottom: 0;
}

.alertTimerBar {
	height:0 ;
	padding:0;
	margin-top:-0.4rem;
	margin-bottom:0;
	border-bottom-width:0.4rem;
	display:block;
}
#globalAlertClose {
	float:right;
	position: static;
	color:black;
	width:2.25rem;
	height:2.2rem;
	line-height:2.2rem
}